<template>
  <div class='special-detail'>
    <div class='special-head'>
      <van-sticky>
        <van-nav-bar @click-left='$router.go(-1)'>
          <template #left>
            <span class='iconfont ico'>&#xe60b;</span>
          </template>
          <template #title>
            <span class='head-title' v-html="topicInfo.name"></span>
          </template>
        </van-nav-bar>
      </van-sticky>
    </div>
    <div class='special-main' ref='pronbit'>
      <div class='special-category'>
        <van-tabs v-model='active' scrollspy sticky :ellipsis="false" :offset-top='40' @rendered="onChange" ref="tabCate">
          <van-tab v-for='(item, index) in topicCategory' :key='index'>
            <template slot='title'>{{ item.categoryName }}</template>
            <div class='special-category-list'>
              <div class='category-name'>
                <div class='c1'>{{ item.categoryName }}<i class='iconfont ico'></i></div>
                <CommonCard v-for='list in topicCategoryList[index]' :list='list' :key='list.id'
                            @toDetail='goDetail'></CommonCard>
                <!-- -->
                <div class="more_btn" @click="moreDetailList(topicCategoryList[index],item.categoryId,index)" v-if="item.moreListBtnShow  && topicCategoryList[index].length > 4">
                  <button>展开更多</button>
                </div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <div class='special-category' v-if='len == 1'>
        <div class='special-category-list'>
          <div class='category-name'>
            <div class='c1'>{{ this.topicCategory[0].categoryName }} <i class='iconfont ico'></i></div>
            <CommonCard v-for='list in topicCategoryList[0]' :list='list' :key='list.id'
                        @toDetail='goDetail'></CommonCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    CommonCard: () => import('@/views/home/CommonCard')
  },
  props: ['id', 'channelId'],
  data() {
    return {
      topicInfo: [],
      topPicture: '',
      topicCategory: [],
      topicCategoryList: [],
      active: 0,
      len: null,
      index: 1,
      flag: true
    }
  },
  created() {
    this.queryTopicDetails()
    // 专题热度加一，返回当前专题总热度
    this.incrTopicHot()
  },
  methods: {
    // 专题热度加一，返回当前专题总热度
    async incrTopicHot() {
      let { data: res } = await this.$api.incrTopicHot(`topicId=${this.id}`)
    },
    // 查询文章详情
    async goDetail(id, articleType, content) {
      if (articleType === 6) {
        this.$router.push({
          path: '/note/' + id + '?from_id=oly',
          query: {
            articleType: 6
          }
        })
      } else if (articleType === 4) {
        window.open(content)
      } else {
        this.$router.push({
          path: '/note/' + id + '?from_id=oly'
        })
      }
    },

    // 查询专题详情
    async queryTopicDetails() {
      let params = `?channelId=${this.channelId}&topicId=${this.id}`
      let { data: res } = await this.$api.queryTopicDetail(params)
      if (res.code !== 200) {
        this.$router.push('/')
        return
      }
      this.topicInfo = res.data;
      this.topicInfo.name = this.topicInfo.name.replace(/\s/g, '&nbsp;')
      this.topPicture = res.data.topPicture
      this.topicCategory = res.data.categoryList
      // 添加每个类目控制“展开更多按钮”
      this.topicCategory.forEach(category=>{
        category.moreListBtnShow = true
      })
      this.len = res.data.categoryList.length
      let sortArry = res.data.list
      let newArry = []
      // 根据tagId将数据处理成类目分类形式
      sortArry.forEach((item) => {
        let array = newArry[item['tagId']] || []
        array.push(item)
        newArry[item['tagId']] = array
      })
      let arr = Object.values(newArry)
      this.topicCategoryList = this.topicCategoryList.concat(arr)
    },
    //查询更多
    async moreDetailList(listArr,categoryId,idx){
      let lastId = listArr[listArr.length-1].id
      let params = {
        categoryId,
        lastId,
        topicId:this.id,
        channelId:this.channelId
      }
      let {data:res} = await this.$api.specialDetailLater(params)
      if(res.code == 321){
        this.topicCategory[idx].moreListBtnShow = false
        // this.$forceUpdate()
        // 根级别的响应式属性Vue.set(object, key, value)
        this.$set(this.topicCategoryList[idx],'moreListBtnShow',false)
        return
      }
      if(res.code !== 200) return this.$toast.fail('获取内容失败');
      if(res.data && res.data.length < 10) {
        this.topicCategory[idx].moreListBtnShow = false
        this.$set(this.topicCategoryList[idx],'moreListBtnShow',false)
      }
      res.data.forEach(item=>{
        this.topicCategoryList[idx].push(item)
      })
    },
    onClickLeft() {
      this.$router.push({
        path: '/'
      })
    },

    onChange() {
      if (this.flag) {
        this.$refs.tabCate.scrollTo(this.$store.state.tabIndex)
        setTimeout(() => {
          this.$refs.tabCate.scrollTo(this.$store.state.tabIndex)
        },50)
        this.active = Number(this.$store.state.tabIndex)
        this.flag = false
      } else {
        console.log(this.$store.state.tabIndex)
        console.log(this.active)
      }
    }
  }
}
</script>

<style lang='less'>
.special-detail {
  /*font-family: PingFang SC, PingFang SC-Regular;*/
  font-size: 13px;
  .special-head {
    width: 100%;
    overflow: hidden;
    .van-nav-bar {
      background: #fff;
      .ico {
        font-size: 25px;
        color: #3a3e45;
      }
    }
  }

  .special-main {
    min-height: 1000px;
    background: #ffffff;
    .special-category {
      padding-bottom: 90px;
      position: relative;

      .van-tabs__line {
        background-color: #F7321C;
      }

      .van-tabs__wrap {
        padding-right: 0;
      }

      .special-category-list {
        .category-name {
          padding-top: 14px;
          font-size: 16px;
          text-align: left;
          color: #3a3e45;

          .c1 {
            padding-left: 14px;
            display: flex;

            .ico {
              margin-top: 4px;
              margin-left: 2px;
              width: 2px;
              height: 2px;
              border-radius: 50%;
              border: 2px solid #F7321C;
              color: #F7321C;
            }
          }
        }
      }
    }
  }

  .more_btn {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 10px 0;
    font-size: 13px;
    color: #F7321C;
    line-height: 40px;
    letter-spacing: 1px;
    button {
      width: 212px;
      height: 40px;
      border: 1px solid #F7321C;
      border-radius: 20px;
      background: #ffffff;
    }
  }
}
</style>
